import React from 'react';

const MainSection = () => {
  return (
    <section className="main-section">
      <h1 className="main-title">
        Comming Soon 🚀
      </h1>
    </section>
  );
};

export default MainSection;