// Import necessary functions from Firebase v9
import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from 'firebase/auth';

// Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyCXXXyGLsBDX_-w2g-IkA9swrLLMHJ7Lsk",
  authDomain: "growaddons.firebaseapp.com",
  projectId: "growaddons",
  storageBucket: "growaddons.firebasestorage.app",
  messagingSenderId: "296880270818",
  appId: "1:296880270818:web:266e22a41af58a8d805dbb",
  measurementId: "G-D4DF1DRT29"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get Auth instance
const auth = getAuth(app);

export { auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile };