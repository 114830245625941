import React, { useState } from 'react';
import { auth, signInWithEmailAndPassword } from '../firebase'; // Import signInWithEmailAndPassword
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Sign in user with email and password
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Navigate to the dashboard after successful login
      navigate('/');
    } catch (error) {
      console.error("Error logging in: ", error.message);
      alert('Failed to login. Please check your credentials!');
    }
  };

  return (
    <main className="register-page">
      <div className="register-container">
        <div className="register-header">
          <a className="register-logo" href="/">
            🌙
          </a>
        </div>

        <form onSubmit={handleSubmit} className="register-content">
          <div className="form-group name-input-container">
            <input
              className="email-input"
              placeholder="Email"
              required
              maxLength="100"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
            />
          </div>

          <div className="form-group password-container">
            <input
              className="password-input"
              placeholder="Password"
              required
              maxLength="100"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name="password"
            />
          </div>

          <div className="flex justify-end text-xs">
            <a className="forgot-pass" href="/register">
              Register?
            </a>
          </div>

          <div className="register-button-container">
            <button type="submit" className="register-button">
              Login
            </button>
          </div>
        </form>
      </div>
    </main>
  );
};

export default Login;