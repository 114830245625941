import React from 'react';

const FeaturesSection = () => {
  const cards = [
    {
      icon: '⚙️',
      title: 'Easy Installation',
      description: 'Effortlessly install and manage addons to enhance your GrowSoft experience without any hassle.',
    },
    {
      icon: '🛠️',
      title: 'Customization Options',
      description: 'Modify your game environment with a variety of custom tools and assets to make it uniquely yours.',
    },
    {
      icon: '📂',
      title: 'Wide Range of Addons',
      description: 'Explore a library of diverse addons to improve everything from gameplay to visuals.',
    },
    {
      icon: '🚀',
      title: 'Performance Boosts',
      description: 'Addons optimized for performance to ensure smooth and enhanced gameplay on GrowSoft.',
    },
    {
      icon: '🔒',
      title: 'Secure & Trusted',
      description: 'Only verified and secure addons are available, so you can download with confidence.',
    },
    {
      icon: '📖',
      title: 'Comprehensive Documentation',
      description: 'Access detailed guides and support to get the most out of each addon.',
    },
  ];

  return (
    <section className="features-section">
      <div className="features-header">
        <h2 className="features-title">🚀 Enhance Your GrowSoft Experience</h2>
        <p className="features-description">
          Discover the best addons for GrowSoft, each designed to provide seamless integration, enhanced performance, and personalized customization. Dive in and elevate your gameplay!
        </p>
      </div>
      <div className="features-cards">
        {cards.map((card, index) => (
          <div className="feature-card" key={index}>
            <div className="card-content">
              <div className="card-icon">{card.icon}</div>
              <p className="card-title">— {card.title}</p>
              <p className="card-description">{card.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FeaturesSection;