import React from 'react';

const MainSection = () => {
  return (
    <section className="main-section">
      <h1 className="main-title">
        Discover Powerful Addons for <span className="highlight-text">GrowSoft</span> 🚀
      </h1>
      <p className="main-description">
        Enhance your GrowSoft experience with our collection of free, high-quality addons designed to elevate your gameplay and customization options.
      </p>
      <div className="main-buttons">
        <a className="button-secondary" href="/downloads">
          Downloads
        </a>
        <a className="button-secondary" href="https://discord.gg/wqkPMKYbw4">
          Discord
        </a>
      </div>
    </section>
  );
};

export default MainSection;