import React, { useState } from 'react';
import { auth, createUserWithEmailAndPassword, updateProfile } from '../firebase'; // Import Firebase auth functions
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Set static profile picture from the public folder
      const profilePicURL = '/first_pfp.png'; // Path to the image in the public folder

      // Update the user's profile with display name and static profile picture
      await updateProfile(user, {
        displayName: name,
        photoURL: profilePicURL, // Set the static profile picture URL
      });

      // Navigate to the dashboard after successful registration
      navigate('/dashboard');
    } catch (error) {
      console.error("Error during registration: ", error.message);
      alert(`Failed to register: ${error.message}`);
    }
  };

  return (
    <main className="register-page">
      <div className="register-container">
        <div className="register-header">
          <a className="register-logo" href="/">
            🌙
          </a>
        </div>

        <form className="register-form" onSubmit={handleSubmit}>
          <div className="name-input-container">
            <input
              className="email-input"
              placeholder="Name"
              required
              maxLength="25"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="name"
            />
          </div>

          <div className="form-group">
            <input
              className="email-input"
              placeholder="Email Address"
              required
              maxLength="50"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
            />
          </div>

          <div className="form-group password-container">
            <input
              className="password-input"
              placeholder="Password"
              required
              maxLength="100"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name="password"
            />
            <button type="button" className="password-toggle-button">
              {/* Optional: Add password visibility toggle */}
            </button>
          </div>

          <div className="terms">
            <p>
              By registering, you agree to our{' '}
              <a className='terms-link' target="_blank" rel="noopener noreferrer" href="/terms">
                Terms of Service
              </a> and{' '}
              <a className='terms-link' target="_blank" rel="noopener noreferrer" href="/privacy">
                Privacy Policy
              </a>.
            </p>
          </div>

          <div className="register-button-container">
            <button type="submit" className="register-button">
              Register
            </button>
          </div>
        </form>
      </div>
    </main>
  );
};

export default Register;