import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      setUser(null);
      history.push('/');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  if (loading) {
    return (
      <header className="navbar">
        <a className="logo" href="/">🌙</a>
        <span>Loading...</span> {/* Show loading indicator */}
      </header>
    );
  }

  return (
    <header className="navbar">
      <a className="logo" href="/">🌙</a>
      {user ? (
        <div className="navbar-container">
        <div className="navbar-content">
          <span className="profileText">Welcome, {user.displayName || user.email}</span>
          <img
            src={user.photoURL || '/first_pfp.png'}
            alt="User Avatar"
            className="user-avatar"
          />
        </div>
        <div className="logout-container">
          <button onClick={handleLogout} className="logout-button">
            Logout
          </button>
        </div>
      </div>
      ) : (
        <div>
          <a className="navbar-button" href="/login">Login</a>
        </div>
      )}
    </header>
  );
};

export default Header;