import React from 'react';

const downloadsList = [
  {
    title: '/buyrpc',
    description: 'Lets You Buy 1 RPC For 5 Blue Gem Locks In-Game',
    downloadLink: '/downloads/buy-rpc.lua',
  },
];

const Downloads = () => {
  return (
    <div>
      <section className="main-section">
        <h1 className="downloads-title">Downloads</h1>
        <p className="downloads-description">
          Browse our collection of high-quality addons for GrowSoft. Click to download and start enhancing your experience today!
        </p>
        <div className="downloads-list">
          {downloadsList.map((addon, index) => (
            <div className="download-card" key={index}>
              <h3 className="addon-title">{addon.title}</h3>
              <p className="addon-description">{addon.description}</p>
              <a href={addon.downloadLink} className="download-button" download>
                Download
              </a>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Downloads;